import axios from 'axios';
import html2pdf from 'html2pdf.js';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import stamp from '../images/WhatsApp_Image_2024-08-12_at_14.08.49-removebg-preview.png';
import labridge from '../images/logo.png';
export default function BillingPrint() {
    const [billData, setBillData] = useState([]);

    function checklogin() {
        if (!localStorage.getItem("userdata")) {
          history("/");
        }
      }
      const history = useNavigate();
    
    
      useEffect(() => {
        checklogin();
      })

    const userdetails = JSON.parse(localStorage.getItem('userdata'));

    const id = localStorage.getItem('billId');
    // const id  = '652eb21ca5501db835d8bedb';

    useEffect(() => {
      bill();
    }, []);    
    async function bill() {
        try {
            const result = await axios.get(`https://apiv2.labridge.in/bill/get?id=${id}`);
          setBillData(result.data.data[0]);
        } catch (error) {
          console.log(error);
        }
      }
    async function downloadPdf() {
        const element = document.getElementById('bill'); // Replace 'your-element-id' with the ID of the element you want to convert to PDF
        html2pdf().from(element).save();
        history('/invoice');

    }

    function NumInWords (number) {
        const first = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
        const tens = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
        const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
        let word = '';
      
        for (let i = 0; i < mad.length; i++) {
          let tempNumber = number%(100*Math.pow(1000,i));
          if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
            if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
              word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
            } else {
              word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + '-' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
            }
          }
      
          tempNumber = number%(Math.pow(1000,i+1));
          if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'hunderd ' + word;
        }
          return word;
      }

      function toTitleCase(str) {
        const titleCase = str
          .toLowerCase()
          .split(' ')
          .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(' ');
      
        return titleCase;
      }

      function changeDateFormat(date) {
        return moment(date).format('DD/MM/YYYY hh:mm A');
      }
  return (
    <div className="container p-3">
        {
            Object.keys(billData).length !== 0 ? 
    (    
        <>  
            <form className="p-3" id='bill'>
                <div className='d-flex justify-content-between' style={{alignItems: 'center'}}>
                    
                    <img src={labridge} width={180} height={60} alt='' />
                </div>
                <hr></hr>
                <div className='d-flex justify-content-center addressHeader'> <b>Address : &nbsp;</b> 1069, U Wing, 1st Floor, Akshar Business Park, Sector 25, Vashi, Navi Mumbai - 400703. </div>
                <hr></hr>
                {/* <div style={{height: '100px'}}></div> */}
                <div className='fs-25 fw-500 d-flex justify-content-center' style={{marginTop: '5px', fontSize: '20px'}}>Bill/Receipt</div>
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-md-6 col-sm-12">
                        <label>
                            <b>Invoice Id:</b> {billData.id.toUpperCase()}
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{justifyContent: 'right'}}>
                        <label>
                            <b>Patient Name:</b> {billData.patientName}
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label>
                            <b>Invoice Date:</b> {changeDateFormat(billData.date)}
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label>
                            <b>Booking Date:</b> {changeDateFormat(billData.bookingdate)}
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{justifyContent: 'right'}}>
                        <label>
                            <b>Age:</b> {billData.age}  
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{justifyContent: 'right'}}>
                        <label>
                            <b>Gender:</b> {billData.gender}
                        </label>
                    </div>
                    <div className="col-md-6 ">
                        <label>
                            <b>Ref Doc:</b> {billData.docName}
                        </label>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label>
                            <b>Source Name:</b> {userdetails.user.labName}
                        </label>
                    </div>
                </div>
                <table style={{ marginTop: '20px', tableLayout: 'auto' }}>
                    <thead style={{ padding: '10px', marginBottom: '10px' }}>
                        <tr style={{ border: '1px solid black', marginBottom: '5px' }}>
                            <th style={{ borderRight: '1px solid black' }}>S No.</th>
                            <th style={{ borderRight: '1px solid black', textAlign: 'start' }}>Test Name</th>
                            <th style={{ borderRight: '1px solid black' }}>Quantity</th>
                            <th>Billing Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {billData.tests.map((item, index) => (
                            <tr
                                key={index}
                                style={{
                                    border: '1px solid black',
                                    marginBottom: '5px',
                                    padding: '5px',
                                }}
                            >   
                                <td className="" style={{ borderRight: '1px solid black' }}>
                                    <span>{index + 1}</span>
                                </td>
                                <td className="" style={{ borderRight: '1px solid black', textAlign: 'start' }}>
                                    <span style={{fontSize: '15px'}}>{item.testName} </span>
                                </td>
                                <td style={{ borderRight: '1px solid black' }}>1</td>
                                <td style={{ borderRight: '1px solid black' }}>
                                {item.price ? item.price.toFixed(2) : ''}

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                    <table style={{ tableLayout: 'fixed', marginTop:"20px" }}>
                        <tbody>
                            <tr>
                                <th colspan="3" style={{width: '79.80%', textAlign: 'start'}}>Total</th>
                                <td colspan="1">{billData.total.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th colspan="3" style={{width: '79.80%', textAlign: 'start'}}>Discount (in rupees)</th>
                                <td colspan="1">
                                    {(billData.discount.toFixed(2))}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="3" style={{width: '79.80%', textAlign: 'start'}}>Final Price</th>
                                <td colspan="1">{billData.finalAmount.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{marginTop: '10px'}}>
                        <span><b>Amount in Words:</b> Rupees {toTitleCase(NumInWords(billData.finalAmount))} Only</span>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <div style={{display: 'flex'}}><b>Note :</b> <ul>
                                                <li>No Refund is available after booking</li>
                                                <li>This is an electronically generated bill, hence does not require <b>signature</b> </li>
                                                <img src={stamp} width={120} height={120}  alt=''/>
                                            </ul></div>
                    </div>
                </form>
                <div>
                    <button className="billingBtn" onClick={downloadPdf}>Download Pdf</button>
                </div>
                </>) : (<></>)
        }
      </div>
  )
}
